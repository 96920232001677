import { Fragment, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import {
  FormControl,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
} from "@material-ui/core";
import InputField from "shared/ui/Input";
import { onlyNumericalHandler, onlyNumericalPasteHandler } from "src/helpers";
import AutocompleteField from "shared/ui/Autocomplete";
import {
  emailRegex,
  onlyEngArmLetterWithDash,
  onlyLatinRusWithNumbersSpace,
  onlyLatinWithNumbersSpace,
} from "src/helpers/validators";
import useGetStateCity from "src/helpers/useGetStateCity";
import { ECountriesList } from "src/views/Orders/data/constants";
import { useTranslation } from "react-i18next";

const requiredRule = (t: any) => {
  return {
    required: {
      value: true,
      message: t("required_field"),
    },
  };
};

const defaultRules = (maxLength: number, t: any) => {
  return {
    ...requiredRule,
    maxLength: {
      value: maxLength,
      message: `${t("maximum")} ${maxLength} ${t("character")}`,
    },
  };
};

const ARTSAKH_STATE_ID = 34;

const AddressPicker = ({ prefix, countryID, isPhoneNumberRequired }) => {
  const { t } = useTranslation();
  const {
    control,
    setValue,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useFormContext();

  const streetWatch = useWatch({
    name: `${prefix}.street`,
    control,
  });

  const cityVillageWatch = useWatch({
    name: `${prefix}.cityVillage`,
    control,
  });

  const isLegalWatch = useWatch({
    name: `${prefix}.isLegal`,
    control,
  });

  const handleStreetChange = (newValue) => {
    handleResetForm([
      `${prefix}.building`,
      `${prefix}.apartment`,
      `${prefix}.postalCode`,
    ]);
    setValue(`${prefix}.street`, newValue);
  };

  const handleStreetInputChange = (newValue) => {
    setValue(`${prefix}.street`, newValue);
  };

  const handleBuildingChange = (newValue) => {
    if (newValue.postIndex) {
      handleResetForm([`${prefix}.apartment`], {
        [`postalCode`]: newValue.postIndex,
      });
    }
  };

  const handleBuildingInputChange = (newValue) => {
    setValue(`${prefix}.building`, newValue);
    if (typeof streetWatch === "string" && cityVillageWatch?.postalCode) {
      const values = getValues();
      reset({
        ...values,
        [prefix]: {
          ...values[prefix],
          postalCode: cityVillageWatch.postalCode,
        },
      });
    }
  };

  const handleResetForm = (resetableFields, fieldsToSetValue = {}) => {
    if (!resetableFields?.length) {
      return;
    }

    let resetable = [];
    resetableFields.forEach((item) => {
      resetable[item.split(".")[1]] = "";
    });

    reset({
      ...getValues(),
      [prefix]: {
        ...getValues()?.[prefix],
        ...resetable,
        ...fieldsToSetValue,
      },
    });
  };

  const {
    addressState: { stateList, cityVillageList, streetList, buildingList },
    dispatchAddress,
  } = useGetStateCity(watch, control, handleResetForm, prefix);

  const handleSelectLegalType = () => {
    setValue(`${prefix}.isLegal`, !isLegalWatch);
  };

  const handleChangeProvinceState = () => {
    handleResetForm([
      `${prefix}.cityVillage`,
      `${prefix}.street`,
      `${prefix}.building`,
      `${prefix}.apartment`,
      `${prefix}.postalCode`,
    ]);
    dispatchAddress({ type: "streetList", payload: [] });
    dispatchAddress({ type: "buildingList", payload: [] });
  };

  const handleChangeCityVillage = () => {
    handleResetForm([
      `${prefix}.street`,
      `${prefix}.building`,
      `${prefix}.apartment`,
      `${prefix}.postalCode`,
    ]);
    dispatchAddress({ type: "streetList", payload: [] });
    dispatchAddress({ type: "buildingList", payload: [] });
  };

  const isPatternForArmenia = useMemo(() => {
    return countryID === ECountriesList.ARMENIA
      ? {
          value: onlyEngArmLetterWithDash,
          message: t("only_latin_arm"),
        }
      : countryID === ECountriesList.RUSSIA ||
        countryID === ECountriesList.MOSCOW
      ? {
          value: onlyLatinRusWithNumbersSpace,
          message: t("only_latin_ru"),
        }
      : {
          value: onlyLatinWithNumbersSpace,
          message: t("only_latin"),
        };
  }, [countryID, t]);

  const postalCodeInput = useMemo(() => {
    return countryID === ECountriesList.ARMENIA ? (
      <InputField
        name={`${prefix}.postalCode`}
        label={t("postal_code")}
        control={control}
        rules={{
          minLength: {
            value: 4,
            message: `${t("minimum")} 4 ${t("character")}`,
          },
          maxLength: {
            value: 4,
            message: `${t("maximum")} 4 ${t("character")}`,
          },
        }}
        onPaste={onlyNumericalPasteHandler}
        onKeyPress={onlyNumericalHandler}
      />
    ) : (
      <InputField
        name={`${prefix}.postalCode`}
        label={t("postal_code")}
        control={control}
      />
    );
  }, [countryID, t]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <RadioGroup>
            <Box display="flex">
              <FormControlLabel
                control={
                  <Radio
                    checked={!isLegalWatch}
                    onChange={handleSelectLegalType}
                  />
                }
                label={t("physical_person")}
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={isLegalWatch}
                    onChange={handleSelectLegalType}
                  />
                }
                label={t("legal_person")}
              />
            </Box>
          </RadioGroup>
        </FormControl>
      </Grid>
      {!isLegalWatch ? (
        <Fragment>
          <Grid item xs={4}>
            <InputField
              name={`${prefix}.name`}
              label={`${t("name")}*`}
              control={control}
              rules={{
                ...defaultRules(50, t),
                pattern: isPatternForArmenia,
                validate: (value: string) => {
                  const reg = /^\s*$/g;
                  return !reg.test(value) || t("required_field");
                },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <InputField
              name={`${prefix}.surname`}
              label={`${t("surname")}*`}
              control={control}
              errors={errors}
              rules={{
                ...defaultRules(50, t),
                pattern: isPatternForArmenia,
                validate: (value: string) => {
                  const reg = /^\s*$/g;
                  return !reg.test(value) || t("required_field");
                },
              }}
            />
          </Grid>
        </Fragment>
      ) : (
        <Grid item xs={8}>
          <InputField
            name={`${prefix}.legalName`}
            label={`${t("organization_name")}*`}
            control={control}
            rules={{
              ...defaultRules(200, t),
              pattern:
                countryID !== ECountriesList.ARMENIA && isPatternForArmenia,
              validate: (value: string) => {
                const reg = /^\s*$/g;
                return !reg.test(value) || t("required_field");
              },
            }}
          />
        </Grid>
      )}

      <Grid item xs={4}>
        <InputField
          name={`${prefix}.email`}
          label={t("email")}
          control={control}
          rules={{
            maxLength: {
              value: 50,
              message: `${t("maximum")} 50 ${t("character")}`,
            },
            pattern: { value: emailRegex, message: t("wrong_email") },
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <InputField
          name={`${prefix}.phone`}
          label={t("phone_number")}
          control={control}
          rules={{
            maxLength: {
              value: 11,
              message: `${t("maximum")} 11 ${t("character")}`,
            },
            required: {
              value: isPhoneNumberRequired,
              message: t("required_field"),
            },
          }}
          onKeyPress={onlyNumericalHandler}
          onPaste={onlyNumericalPasteHandler}
        />
      </Grid>
      {countryID === ECountriesList.ARMENIA ? (
        <Fragment>
          <Grid item xs={4}>
            <AutocompleteField
              isTranslated
              size="medium"
              name={`${prefix}.provinceState`}
              label={`${t("provinceState")}*`}
              control={control}
              rules={requiredRule(t)}
              onChange={handleChangeProvinceState}
              options={stateList || []}
              disabled={!stateList.length}
              error={errors}
            />
          </Grid>
          <Grid item xs={4}>
            <AutocompleteField
              size="medium"
              name={`${prefix}.cityVillage`}
              label={`${t("cityVillage")}*`}
              freeSolo
              autoSelect
              control={control}
              onChange={handleChangeCityVillage}
              options={cityVillageList || []}
              disabled={
                !cityVillageList.length &&
                watch(`${prefix}.provinceState`)?.id !== ARTSAKH_STATE_ID
              }
              rules={{ ...defaultRules(50, t), ...requiredRule(t) }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={4}>
            <AutocompleteField
              size="medium"
              name={`${prefix}.street`}
              label={`${t("street")}*`}
              autoSelect
              freeSolo
              isInputChange
              onInputChange={handleStreetInputChange}
              onChange={handleStreetChange}
              rules={{ ...defaultRules(50, t), ...requiredRule(t) }}
              control={control}
              options={streetList || []}
              disabled={!watch(`${prefix}.cityVillage`)}
              errors={errors}
            />
          </Grid>
          <Grid item xs={4}>
            <AutocompleteField
              size="medium"
              name={`${prefix}.building`}
              label={`${t("building")}*`}
              freeSolo
              autoSelect
              isInputChange
              onInputChange={handleBuildingInputChange}
              onChange={handleBuildingChange}
              disableClearable={true}
              control={control}
              options={buildingList || []}
              rules={{ ...defaultRules(50, t), ...requiredRule(t) }}
              errors={errors}
            />
          </Grid>
        </Fragment>
      ) : (
        <Fragment>
          <Grid item xs={4}>
            <InputField
              name={`${prefix}.provinceState`}
              label={`${t("provinceState")}*`}
              control={control}
              rules={{
                ...requiredRule(t),
                pattern: {
                  value: onlyLatinWithNumbersSpace,
                  message: t("only_latin"),
                },
              }}
              error={errors}
            />
          </Grid>
          <Grid item xs={4}>
            <InputField
              name={`${prefix}.cityVillage`}
              label={`${t("cityVillage")}*`}
              control={control}
              rules={{
                ...defaultRules(50, t),
                ...requiredRule(t),
                pattern: {
                  value: onlyLatinWithNumbersSpace,
                  message: t("only_latin"),
                },
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={4}>
            <InputField
              name={`${prefix}.street`}
              label={`${t("street")}*`}
              rules={{
                ...defaultRules(50, t),
                ...requiredRule(t),
                pattern: {
                  value: onlyLatinWithNumbersSpace,
                  message: t("only_latin"),
                },
              }}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs={4}>
            <InputField
              name={`${prefix}.building`}
              label={`${t("building")}*`}
              control={control}
              rules={{
                ...defaultRules(50, t),
                ...requiredRule(t),
                pattern: {
                  value: onlyLatinWithNumbersSpace,
                  message: t("only_latin"),
                },
              }}
              errors={errors}
            />
          </Grid>
        </Fragment>
      )}
      <Grid item xs={4}>
        <InputField
          name={`${prefix}.apartment`}
          label={t("apartment")}
          control={control}
          rules={{
            maxLength: {
              value: 11,
              message: `${t("maximum")} 11 ${t("character")}`,
            },
          }}
        />
      </Grid>
      <Grid item xs={4}>
        {postalCodeInput}
      </Grid>
    </Grid>
  );
};

export default AddressPicker;
